import React from 'react';
import styled from 'styled-components';

import device from '../device';

import { TimeTable } from '../components/timetable';
import Layout from '../components/layout';
import SEO from '../components/seo';

const TimeTableSectionContainer = styled.div`
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media ${device.tablet} {
        margin-bottom: 3rem;
    }

    @media ${device.tabletL} {
        margin-bottom: 4rem;
    }

    @media ${device.laptop} {
        width: 90%;
        margin: 3rem auto;
    }
`;

export default props => (
    <Layout {...props}>
        <SEO
            title="מערכת שעות"
            keywords={[
                `פילאטיס בראשון לציון`,
                `זומבה בראשון לציון`,
                `עיצוב וחיטוב בראשון לציון`,
                `סטודיו לנשים בראשון לציון`,
            ]}
        />
        <TimeTableSectionContainer>
            <TimeTable />
        </TimeTableSectionContainer>
    </Layout>
);
